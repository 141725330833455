:root {
  --general-secondary-color: #f0f3f9; /* Default value */
  --bg-white-color: #ffffff; /* Default value */
  --general-white-color: #ffffff;
  --general-success: #009a29;
  --general-error: #f04248;
  --gray-gray-50: #e6e7e8;
  --gray-gray-100: #b3b3b6;
  --gray-gray-200: #8e8e93;
  --gray-gray-300: #5a5b62;
  --gray-gray-400: #3a3b43;
  --gray-gray-500: #090a14;
  --gray-gray-600: #040408;
  --blue-blue-100: #e8f1fe;
  --blue-blue-200: #b8d5fb;
  --blue-blue-300: #95c0f9;
  --blue-blue-400: #65a4f6;
  --blue-blue-500: #1977f2;
  --blue-blue-600: #17c6d6;
}

.gray-gray-500 {
  color: var(--gray-gray-500);
}
.gray-gray-400 {
  color: var(--gray-gray-400);
}
.gray-gray-300 {
  color: var(--gray-gray-300);
}
.app-container {
  display: flex;
  flex:1;
}
@media (max-width: 768px) {
  .app-container {
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  
}
.m-t-10{
  margin-top: 10px;
}
.main-layout {
  display: flex;
  flex: 1;
  background-color: #f0f3f9;
  min-height:100vh;
}
.content-container {
  flex:0.7;
  padding:60px 20px 20px 110px;
  max-width: 60vw;
  min-height: 80vh;;
  /* background: #f8f9fa; */
  overflow-y: auto;
  border-radius: 6px;
  scroll-behavior: smooth;
}
.right-main-container {
  flex: 0.3 1;
    padding: 60px 0px 0px 0;
    text-align: center;
    min-height: 100px;
    height: 100vh;
    border-radius: 6px;
    position: fixed;
    right: 0;
    left: 70%;
    flex-shrink: 0;
    scroll-behavior: smooth;
}
@media (max-width: 768px) {

  .sidebar {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
  }

  .content-container {
    margin-left: 0; 
    margin-right: 0; 
    height: 100vh;
    display: contents;
    padding:0 ;
  }

  .right-main-container {
    display: none; 
  }
}

.loader-mid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:25% ;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 4px solid #007bff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #007bff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hidden {
  display: none;
}

.small-loader {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin: auto;
}

.small-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid #007bff;
  border-radius: 50%;
  animation: small-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.small-loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.small-loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.small-loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes small-lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-datepicker-size {
  width: 100%;
  max-width: 730px;
  box-sizing: border-box;
  font-family: Inter-Medium;
  font-size: 16px;
}

.datepicker-bid-list {
  width: 460px;
  box-sizing: border-box;
  border: 1px solid #a6a5bb;
  padding: 10px;
  border-radius: 2px;
  font-size: 16px;
}

.date-picker {
  width: 90vw;
  min-width: 298px;
  box-sizing: border-box;
}

.react-datepicker-popper {
  z-index: 100 !important;
}
.react-datepicker-size:focus-visible {
  outline: rgb(179, 179, 179);
  border: 1px solid rgb(179, 179, 179);
}

.custom-text-area {
  width: 100%;
  padding: 10px;
  border: 1px solid #a6a5bb;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  font-family: Inter-Medium;
  outline: none;
  resize: vertical;
  min-height: 75px;
}

.text-dark {
  color : #212529;
}
.text-color-p {
  color: #6E6E6E;
}

.custom-input-box::-webkit-outer-spin-button,
.custom-input-box::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-input-box ::placeholder {
  color: #a6a5bb;
  font-size: 10px;
}

.centered-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
}

.container {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.content {
  text-align: left;
  text-align: left;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-wrapper {
  margin-bottom: 10px;
}

.text-wrapper h2,
.text-wrapper p {
  margin: 8px 0;
}

.react-dropdown-select {
  border: 1px solid #a6a5bb;
  background-color: white;
  height: 44px;
  border-radius: 6px;
  font-family: "Inter-Medium";
  display: flex;
  align-items: center;
}
.react-dropdown-select-input {
  height: 100%;
  border-radius: 6px;
}
.react-dropdown-select-input::placeholder {
  color: #090A14;
  font-family: "Inter-Medium";
  font-size: 15px;
}

.custom-input-box {
  padding: 10px;
  border: 1px solid #a6a5bb;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  font-family: Inter-Medium;
  height: 44px;
}
.fixed-button-container {
  position: fixed;         
  bottom: 1px;            
  left: 50%;               
  transform: translateX(-50%); 
  background-color: white; 
  padding: 10px 0;
  z-index: 9999;          
  width: 100%;            
  max-width: 730px;  
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-button-bottom {
  width: 100%;      
  max-width: 480px;      
  padding: 12px;
  background-color: rgb(0, 102, 255); 
  color: white;
  border: none;
  height: 50px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}


.custom-button {
  width: 100%;
  padding: 12px;
  background-color: rgb(0, 102, 255);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-weight: 300;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.custom-button-inside-card {
  width: 100%;
  padding: 12px;
  background-color: rgb(0, 102, 255);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 38px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
@media (max-width:768px){
  .custom-button-inside-card{
  width:100%
  }
}

.custom-button-bg-white {
  width: 100%;
  padding: 12px;
  background-color: #fff;
  border: 2px solid #ccc;
  color: rgb(38, 38, 38) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-weight: bolder;
  border-radius: 4px;
  font-size: 16px;
}

.button-container {
  width: 100%;
}

.custom-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.custom-select-box {
  width: 88.5vw;
  padding: 5px;
  border: 1px solid #a6a5bb;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}

.font-bold {
  font-weight: 500;
}
.progress-bar {
  display: flex;
  justify-content: flex-start;
}
.bar1 {
  border: 3px solid black;
  width: 50%;
}
.bar0 {
  border: 3px solid #dadada;
  width: 50%;
}
.bar2 {
  border: 3px solid black;
}
.error {
  color: #D8313B;
  font-size: 12px;
  margin-top: 2px;
}
.success {
  color: #02AD45;
  font-size: 12px;
  margin-top: 2px;
}
.green {
  color: rgb(4, 206, 4);
}
.btn-bottom {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
.changeNo {
  display: flex;
  justify-content: space-between;
}
  
 .btn-container-details-page {
  width: 100%;
  background-color: #ffffff;
  max-width: 768px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}


.btn-bottom-detail-page {
  margin: 10px 0;
  width: 100%;
  padding: 12px;
  background-color: rgb(0, 102, 255);
  color: white;
  border: none;
  height: 50px;
  border-radius: 6px;
  font-size: 16px;
}

.otp-container {
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  margin-top: 15px;
  width: 100%;
}

.otp-input-style {
  width: 60px;
  height: 60px;
  padding: 15px;
  border: 2px solid #a6a5bb;
  border-radius: 8px;
  font-size: 20px;
  text-align: center;
  outline: none;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  transition: all 0.3s ease;
  background-color: #f8f8f8;
}

.otp-container-style {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.otp-input {
  width: 50px;
  font-size: 1.1rem;
  padding: 7px;
  border-radius: 8px;
  text-align: center;
  background-color: #f8f8f8;
  border: 2px solid #a6a5bb;
  outline: none;
  transition: all 0.3s ease;
}

.header-credit-left {
  padding: 10px 8px 10px 8px;
}
.reelax-logo {
  width: 40px;
}

.header-fixed,
.header-fixed-list {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.header-fixed {
  position: fixed;
  padding:  18px 0;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1)
}
.header-fixed-list {
  position: fixed;
  padding-top: 15px;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1)

}
.home-header-fixed {
  padding: 15px 0;
  position: fixed;
  background-color: #fff;
  width: 100%;
  display: flex;
  z-index: 1;
  top: 1;
  margin-bottom: 10px;
  /* box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1) */
}
.text-rem {
  font-size: 1.2rem;
}

.socailMediaConnect {
  padding: 8px;
  background-color: white;
  border-radius: 5px;
  font-family: Poppins-Medium;
}
.bg-app-gray {
  display: flex;
  flex-direction: column;
  background-color: #f0f3f9;
  min-height: 100vh;
}
.small-txt {
  font-weight: 200;
  font-size: 12px;
  margin-top: 5px;
}
.brand-logo {
  width: 62px;
  height: 62px;
  border-radius: 50%;
}

.category-container {
  display: flex;
  overflow-x: auto;
  margin-top: 10px;
  /* padding: 10px; */
  scrollbar-width: none;
}

.category-container::-webkit-scrollbar {
  display: none;
}

.category-box {
  background-color: #ffffff;
  padding: 5px 10px;
  height: 35px;
  border-radius: 4px;
  margin-right: 10px;
  text-align: center;
  color :#090A14 ; 
   display: flex;
  align-items: center;
  white-space: nowrap;
}

.card-campaign {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* width: 98%; */
  cursor: pointer;
  gap: 7px;
}

.card-icon-campaign img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.card-bid-contract {
  display: flex;
  /* align-items: center; */
  min-height: 70px;
  background-color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* width: 98%; */
  cursor: pointer;
  gap: 12px;
}


.card-icon-bid-contract img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.card-content-campaign {
  flex: 1;
  padding-left: 12px;
  justify-content: space-evenly;
  align-items: center;
}

.card-title-campaign {
  font-weight: 500;
  font-size: 16px;
  color: #212529;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  width: 100%;
  display: block;  
}

.ellipsisText {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
  display: block; 
}


.card-timing-campaign {
  color: #6E6E6E;
  font-size: 12px;
  margin-top: 2px;
}

.card-details-campaign {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #212529;
}

.card-footer-campaign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

.credits-campaign {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #FD8023;
  font-size: 12px;
  font-weight: 500;
}

.credit-icon-campaign {
  margin-right: 4px;
}

.proposals-campaign {
  color: #2680EB;
  font-size: 11px;
  font-weight: 500;
  margin-right: 10px;
}
.popular-invited {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.small-txt-pop-invited {
  font-weight: 200;
  font-size: 12px;
  text-align: center;
}
.tab {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding-bottom: 15px;
}
.active-tab {
  border-bottom: 2px solid black;
}

.whatsapp-container {
  margin-bottom: 10px;
  background-color: #075e5480;
  position: relative;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  font-family: Poppins-Medium;
}

.do-donot-text {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}
.term-proposal-text {
  background-color: #f0f3f9;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.white-card-bg {
  background-color: white;
  border-radius: 6px;
  padding: 8px 15px;
  margin-bottom: 8px; 
  word-wrap: break-word; /* Ensures long words break onto the next line */
  overflow-wrap: break-word; 
}
.white-card-brand-data {
  background-color: white;
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 8px; 
}
.anchor-tag {
  color: #007bff;
  font-size: 12px;
  text-decoration: none;
}

.modal-close-btn {
  position: absolute;
  top: 3%;
  /* width: 20px; */
  bottom: 0;
  height: 20px;
  right: 6%;
}

.arrow-icon-right {
  position: absolute;
  right: 15px;
  top: 48%;
  transform: translateY(-50%);
}
.arrow-icon-right-help {
  position: absolute;
  right: 15px;
  top: 55%;
  transform: translateY(-50%);
}
.camera-icon {
  position: absolute;
  bottom: -3px;
  right: 15px;
}
.header-section {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  padding-left: 100px;
}
.bg-credits {
  background-color: #fbc12f;
  /* padding: 5px 6px; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.credits-box-text {
  color: white;
  font-size: 13px;
}
.btn-help {
  color: #212529;
  border:  0.2vw solid #212529;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  background: white;
  font-weight: 600;
  font-size: 14px;
}

/* ------- Footer Sections ------- */
.footer {
  position: fixed;
  top:0px;
  left: 0;
  max-width: 50px; 
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  padding: 75px 20px 0 20px; 
  box-sizing: border-box; 
}

@media only screen and (max-width: 768px) {
  .header-section {
    padding-left: 20px;
  }
.footer { 
  position: sticky;
  bottom: 0;
  flex-shrink: 0;
  max-width: 768px;
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 40px 0;
  box-sizing: border-box;
}
}


.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon svg {
  fill: gray; /* Default color for icons */
  transition: fill 0.3s;
}

.icon.selected svg {
  fill: #007bff; /* Color for the selected icon */
}

.icon:not(.selected):hover svg {
  fill: darkgray;
}

.red {
  color: red;
}

.fallback {
  /* margin-top: 7px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  background-color: #fff;
}
.bottom-tab {
  border-top: 1px solid rgb(195, 196, 197);
  min-height: 35px;
  cursor: pointer;
  /* position: absolute; */
  bottom: 0px;
  right: 0;
  left: 50px;
  padding-top: 18px;
}
.bottom-message-input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 97%;
  padding: 8px;
  padding-top: 10px;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
}
.message-btn {
  width: 15%;
  padding: 12px;
  background-color: rgb(0, 102, 255);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-weight: 300;
  border-radius: 4px;
  font-size: 10px;
  cursor: pointer;
  margin-right: 2px;
}
.credit-select-box {
  border: 1px solid #dad2d2;
  padding: 10px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: white;
  cursor: pointer;
}

.credit-select-box.selected {
  border: 1px solid black;
}
.brand-container {
  overflow-x: auto; 
  padding: 0 10px 0 0 ; 
}

.brand-card {
  background: #fff;
  display: flex;
  height: 70px;
  width: 100%;
  border-radius: 5px;
  align-items: center;
  padding-left: 8px;
  gap: 10px;
  overflow: hidden;
  margin-left: 2px;
}

.brand-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 3px;
  max-width: 98%;
}

.brand-icon img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}


.dashed-line {
  border: 0;
  border-top: 0.3vw dashed #000;
}

.bid-counter-card {
  background-color: #eee;
  margin-right: 12px;
  margin-top: 10px;
  padding: 6px ;
}
.bid-btn-counter{
  padding: 12px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-size: 10px;
}
.verify-otp-btn{
  width: 100px;
  height: 50px;
  padding: 12px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 10px;
}
.bid-btn-counter-reject{
  background-color: #eee;
  color: black;
  border: 0.1vw solid black;
  cursor: pointer;
  border-radius: 3px;
  font-size: 10px;
  opacity: 0.9;
  font-weight: bold;
  height: 30px;
  width: 50px;
}
.social-box{
  display:  flex ;
  justify-content: space-between;
  align-items: center;
  border : 0.3vw solid #cbcbcb; 
  border-radius:  6px;
  height: 40px;
  padding: 0 10px;
}
.icon-container img {
  margin-right: 8px; 
}
.top-brands{
  display:flex;
  padding:20px 0 0 0;
  gap:50px;
  overflow-x: auto;
        scrollbar-width: none;
}

.inner-main-container{
  width:100%;
  min-height: 80vh;
  overflow-y: auto;
  padding:0 20px ;
  border-radius: 10px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.main-container{
  display: flex;
  gap: 20px;
  /* height:100%; */
  /* max-width: 768px; */
  /* padding:20px; */
  overflow-y: auto;
  /* scrollbar-width: none; */
  scroll-behavior: smooth;
}

.sidebar-container-component {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  overflow-y: auto;
  transition: opacity 0.9s ease;
  pointer-events: none;
  opacity: 0;
}

.sidebar-container-component.open {
  pointer-events: auto;
  opacity: 1;
}

.sidebar-overlay-component {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.sidebar-content-component {
  position: fixed;
  /* min-width: 300px;
  max-width: 480px; */
  /* width: 40%; */
  right: -100%;
  height: 100%;
  display: flex;
  right: 0;
  z-index: 1000;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 5px 0 0 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transition: transform 0.5s ease-in-out;
  transform: translateX(100%);
}

.sidebar-container-component.open .sidebar-content-component {
  transform: translateX(0);
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 16px;
  letter-spacing: 0.5px;
  gap: 10px;
  font-weight: 550;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0 -1px 10px #e6e7e8;
  background-color: white;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 9;
  overflow-y: auto;
  scrollbar-width: none;
  /* position: relative;
  top: 150px; */
}

.sidebar-footer {
  position: sticky;
  flex-shrink: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 15px 20px;
  box-shadow: 0 1px 10px var(--gray-gray-50);
  background-color: white;
}
.close-btn{
 position: fixed;
 right: 10px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.sidemenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 60px;
  height: 100vh;
  z-index: 10;
  background-color: #ffffff;
  display: flex;
  box-shadow: rgba(57, 76, 96, 0.15) 1px -1px 2px;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
  gap: 30px;
  padding:0 12px;
}

/* Expand sidebar on hover */
.sidemenu:hover {
  width: 180px;
}
.sidemenu-items.selected {
  background-color: #E8F1FE ;
  color: #1977F2;
  border-radius: 6px;
}
/* Sidebar Mobile (Bottom Bar) */
@media (max-width: 768px) {
  .sidemenu {
    position: sticky;
    height: 0px;
  }
  .sidemenu:hover {
    width: 100%;
  }

  .reelax-img {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .top-brands{
    overflow-x: auto;
    gap: 20px;
        scrollbar-width: none;
  }
  .inner-main-container{
    padding: 65px 20px;
    overflow-y: auto;
    margin:0;
    /* height: 100vh; */
  }
  .inner-main-container-right{
    display: none;
  }
  .main-container{
    width:100%;
  }
  .sidemenu-items-container{
    position: fixed;
    justify-content: space-around;
    display: flex;
    background: white;
    bottom: 0;
    left: 0;
    height:50px;
    box-shadow: rgba(57, 76, 96, 0.15) 1px -1px 2px;
    right: 0;
  }
  .sidemenu-items{
    padding: 18px !important;
  }
  .sidemenu-items.selected{
    background-color: #ffffff !important;
  }
}

.nav-icon{
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
.nav-icon svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.nav-text{
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 48px;
}
.sidemenu:hover .nav-text {
  opacity: 1;
}
.reelax-img-full{
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  left: 40px;
}
.sidemenu:hover.reelax-img-full{
  opacity: 1;
}
/* Mobile adjustments */
@media (max-width: 768px) {

  .nav-icon {
    margin-right: 0;
  }
    .nav-icon {
    margin-right: 0;
  }

  .nav-text {
    display: none;
  }
}
.sidemenu-items{
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 20px 10px 20px 18px;
  height:10px;
  position: relative;
}
.sidemenu-items:hover{
background-color: #E8F1FE;
color:#1977F2;
border-radius: 6px;
}

.reelax-img{
display: flex;
 align-items: center;
 padding: 40px 0px 10px 0;
  cursor:pointer;
  justify-content: center;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Container */
.popup-container {
  position: relative;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: visible !important;
  max-height: 90%;
  display: flex;
  flex-direction: column;
}

/* Header */
.popup-header {
  background: var(--bg-white-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  border-radius: 8px 8px 0 0;
  padding: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); 
  z-index: 10;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Content */
.popup-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-y: auto;
  min-height: 50px;
}

/* Footer */
.popup-footer {
  padding: 12px 20px;
  display: flex;
  background: var(--bg-white-color);
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 10;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
}

.footer-button {
  flex: 1;
  border: none;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  color: var(--bg-white-color);
  cursor: pointer;
  background: var(--gray-gray-400);
}
.footer-button:hover {
  background: var(--gray-gray-500);
}

.footer-button.primary {
  background: #007bff;
  color: white;
}
.footer-button.primary:hover {
  background-color: #176cdc !important;
}

/* Responsive Text */

@media (max-width: 768px) {
  .popup-container {
    width: 80% !important;
    max-height: 600px !important;
  }
  .popup-header,
  .popup-content {
    font-size: calc(1rem + 0.2vw);
  }
  .footer-button {
    font-size: calc(1rem + 0.2vw);
    padding: 10px 60px !important;
  }
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.bottomsheet-header {
  text-align: center;
  padding: 20px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.bottomsheet-content {
  padding: 20px;
  overflow: visible !important;
}
.bottomsheet-footer {
  padding: 10px 20px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 10;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
  background: var(--bg-white-color);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.bottomsheet-footer-button {
  padding: 10px 40px;
  border: none;
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  height: 50px;
  border-radius: 5px;
  color: var(--bg-white-color);
  cursor: pointer;
  background: var(--gray-gray-400);
}
.bottomsheet-footer-button:hover {
  background: var(--gray-gray-500);
}

.bottomsheet-footer-button.primary {
  background: #007bff;
  color: white;
}
.bottomsheet-footer-button.primary:hover {
  background-color: #176cdc !important;
}
.mobile-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}
@media (max-width:768px){
  
}
.mobile-footer .sidemenu-items-container {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
@media (max-width: 768px) {
  .nav-text {
    display: none;
  }

  .mobile-footer .nav-text {
    display: none;
  }
}

.analytics-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
}

.progress-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.progress-bar-analytics {
  height: 6px;
  background: #e0e0e0;
  border-radius: 3px;
  overflow: hidden;
  width:100%;
}

.progress-fill {
  height: 100%;
  background: rgb(224 0 150 / 22%);
  border-radius: 3px;
}
.active-time-chart {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  height: 200px; /* Fixed height container */
  width: 100%;
  margin-top: 15px;
  position: relative; /* Ensure bars are positioned correctly */
}

.bar-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.bar {
  width: 10%;
  background:rgb(224 0 150 / 22%);
  border-radius: 5px;
  transition: height 0.3s ease-in-out;
  position: absolute;
  bottom: 0; 
}
.day-label{
  display: flex;
  position: relative;
}
.steps-container {
  padding: 16px 0 0 0;
}

.steps-container h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.step {
  margin-bottom: 12px;
}

.step h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.step p {
  font-size: 14px;
  color: #666;
  margin: 4px 0 0;
}
