@font-face {
  font-family: "Inter-Thin"; /*100 */
  /* src: url("../fonts/Inter-Thin.ttf"); */
  src : url("/public/fonts/Inter-Thin.ttf")
}

@font-face {
  font-family: "Inter-ExtraLight"; /*200 */
  src: url("/public/fonts/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: "Inter-Light"; /*300 */
  src: url("/public/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter-Regular"; /*400 */
  src: url("/public/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Medium"; /*500 */
  src: url("/public/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-SemiBold"; /*600 */
  src: url("/public/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Bold"; /*700 */
  src: url("/public/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter-ExtraBold"; /*800 */
  src: url("/public/fonts/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: "Inter-Black"; /*900 */
  src: url("/public/fonts/Inter-Black.ttf");
}

* {
  padding: 0;
  margin: 0;
  font-family: "Inter-Regular";
  /* font-family: Poppins-Medium; */
}
.font-light{
  font-family: Inter-light;
}
.font-400{
  font-weight: 400;
}
.m-t-4 {
  margin-top: 4px;
}
.p-t-3{
  padding-top: 1px;
}
.p-t-10{
  padding-top: 10px;
}
.p-t-58{
  padding-top: 58px;
}
.m-t-2 {
  margin-top: 2px;
}
.opacity {
  opacity: 0.9;
}
.m-t-6 {
  margin-top: 6px;
}
.m-t-8 {
  margin-top: 8px;
}
.m-t-12 {
  margin-top: 12px;
}
.m-t-16 {
  margin-top: 16px;
}
.m-t-18 {
  margin-top: 18px;
}
.m-t-24 {
  margin-top: 24px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-32 {
  margin-top: 32px;
}
.m-t-36 {
  margin-top: 36px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-t-50 {
  margin-top: 50px;
}
.gap-5{
  gap: 5px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-t-180 {
  margin-top: 180px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-3 {
  margin-bottom: 3px;
}
.m-b-8 {
  margin-bottom: 8px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-b-12 {
  margin-bottom: 12px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-4 {
  margin-bottom: 4px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-b-18 {
  margin-bottom: 18px;
}
.m-b-19 {
  margin-bottom: 19px;
}
.m-r-4 {
  margin-right: 4px;
}
.m-r-6 {
  margin-right: 6px;
}
.m-r-8 {
  margin-right: 8px;
}
.m-r-12 {
  margin-right: 12px;
}
.m-r-20 {
  margin-right: 20px;
}

.m-r-32 {
  margin-right: 32px;
}
.m-r-100 {
  margin-right: 100px;
}
.m-r-10 {
  margin-right: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-l-7 {
  margin-left: 7px;
}
.m-l-8 {
  margin-left: 8px;
}
.m-l-12 {
  margin-left: 12px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-23 {
  margin-left: 23px;
}
.m-l-24 {
  margin-left: 24px;
}
.m-l-32 {
  margin-left: 32px;
}

.m-b-20 {
  margin-bottom: 20px;
}
.m-b-25 {
  margin-bottom: 23px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-b-4 {
  margin-bottom: 4px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.margin-auto {
  margin: auto;
}
.p-r-4{
  padding-right: 4px;
}
.p-r-8{
  padding-right: 8px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-32 {
  padding-right: 32px;
}
.m-r-16 {
  margin-left: 16px;
}

.m-r-10 {
  margin-right: 10px;
}
.p-t-60{
  padding-top: 60px;
}
.p-t-12{
  padding-top: 12px;
}
.p-t-70{
  padding-top: 70px;
}
.p-t-75{
  padding-top: 75px;
}
.p-20{
  padding: 20px;
}
.p-l-25{
  padding-left: 25px;
}
.p-l-20{
  padding-left: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-b-6 {
  padding-bottom: 6px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-b-70 {
  padding-bottom: 70px;
}
.full-width {
  width: 100%;
}
.h-90 {
 height : 80vh;
}
.h-full {
 height : 100vh;
}
min-h{
  min-height: 70vh;
}
.p-b-40{
  padding-bottom: 40px;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.full-height{
  height: 90vh;
}
.relative{
  position: relative;
}
.cursor-pointer
.flex-row {
  display: flex;
  flex-direction: row;
}
.text-end{
  text-align: end;
}
.align-center {
  align-items: center;
}
.text-align {
  text-align: center;
}
.button-text {
  text-wrap: nowrap;
}
.flex-wrap{
  flex-wrap:  wrap;
  width: 100%;
}

.justify-center {
  justify-content: center;
  display: flex;
}

.letter-spacing {
  letter-spacing: 3px;
}
.right {
  float: right;
}
.left {
  float: left;
}
.flex{
  display: flex;
}
.danger {
  color: "#D0342C";
}
.font-light
.dark{
  color: #212529;
}
.text-align-start {
  text-align: start;
}
.text-align-end {
  text-align: end;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.text-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.width-200 {
  max-width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}
.justify-between {
  justify-content: space-between;
}

.font-600{
  font-weight:  600;
}
.text-13{
  font-size: 13px;
}

.text-1rem{
  font-size: 1.1rem;
}
.text-12{
  font-size: 12px;
}
.text-16{
  font-size: 16px;
}
.text-18{
  font-size: 18px;
}

.text-14{
  font-size: 14px;
}
.text-15{
  font-size: 15px;
}
.gap-30{
  gap:30px
}
.gap-10{
  gap:12px
}
.m-t-b-10{
  margin-top: 10px;
  margin-bottom: 10px;
}
.capitalize{
  text-transform:  capitalize; 
}

.black {
  color: black;
}
/* color class */

.subtitle1 {
  font-family: "Inter-Regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
}
.button2 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: 0em;
  text-align: left;
}
.button1 {
  font-family: "Inter-Medium";
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.button-text {
  font-family: "Inter-SemiBold";
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}

.h6Text {
  font-family: "Inter-SemiBold";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.Body-1 {
  font-family: "Inter-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.Caption-3 {
  font-family: "Inter-Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  letter-spacing: 0em;
  text-align: left;
}
.h5text {
  font-family: "Inter-SemiBold";
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.Caption1 {
  font-family: "Inter-Regular";
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.caption_2 {
  font-family: "Inter-Regular";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.caption_2_10 {
  font-family: "Inter-Regular";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
}

.typographyH5 {
  font-family: "Inter-Regular";

  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
}
.typographyBoldH5 {
  font-family: "Inter-Regular";
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.typographyH7 {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.typography-centerH7 {
  font-family: "Inter-Regular";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.typography {
  font-family: "Inter-Regular";
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.typography14 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.typography-25 {
  font-family: "Inter-Regular";
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.page-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-all-20{
  margin: 20px;
}
.m-all-10{
  margin: 10px;
}
